import { cloneDeep } from 'lodash';

import { AuthState } from './Auth.interface';
import { IMPERSONATE_LAST_PAGE, STAFF_EMAIL, STAFF_ID, STAFF_TOKEN_WHILE_IMPERSONATE } from './constants';

import User from 'modules/Auth/common/User.interface';
import Token from 'modules/Auth/common/Token.interface';
import { buildInitialState, setLocalStorage, setDebugUsers } from 'modules/Auth/AuthProvider/utils';

export const initialState = buildInitialState();

export const reducer = (state: AuthState, action) => {
    let user: User | null = { ...state.user } as User;
    let token: Token | null = { ...state.token } as Token;
    let newState;

    const isImpersonating = Boolean(localStorage.getItem(STAFF_EMAIL));

    switch (action.type) {
        case 'SET_TOKEN':
            token = { ...token, ...action.payload };
            newState = { ...state, token };
            break;
        case 'SET_USER_DATA':
            user = { ...user, ...action.payload };
            newState = { ...state, user };
            break;
        case 'SET_EXTEND_SESSION_MODAL_VISIBLE':
            newState = { ...state, showTimeoutModal: action.payload };
            break;
        case 'CLEAR_SESSION':
            user = null;
            token = null;
            newState = { ...initialState, user, token };
            setLocalStorage(STAFF_EMAIL, null);
            setLocalStorage(STAFF_ID, null);
            setLocalStorage(STAFF_TOKEN_WHILE_IMPERSONATE, null);
            setLocalStorage(IMPERSONATE_LAST_PAGE, null);
            break;
        default:
            newState = cloneDeep(state);
    }
    setLocalStorage('token', !!token && Object.keys(token).length > 0 ? token : null);
    setLocalStorage('user', user);
    setLocalStorage('email', user?.email || null);
    setLocalStorage('employee', isImpersonating ? false : user?.is_employee);
    setLocalStorage('impersonator', isImpersonating ? false : Boolean(user?.groups?.includes('Impersonators')));
    setLocalStorage('loggedin', Boolean(token?.access));
    // set groups for use by useAccessGroups hook
    if ((user?.groups?.length ?? 0) > 0) {
        setLocalStorage('groups', JSON.stringify(user?.groups));
    } else {
        setLocalStorage('groups', null);
    }

    if (user?.pk) {
        setDebugUsers(user);
    }

    return newState;
};
