import { datadogRum } from '@datadog/browser-rum';
import * as Sentry from '@sentry/react';

import { AuthState } from './Auth.interface';
import { STAFF_ID } from './constants';

import User from 'modules/Auth/common/User.interface';

const PUBLIC_ROUTES: string[] = [
    '/manual-sales/:initialTask/:funnelWorkflowId',
    '/ssc-v05/:initialTask/:funnelWorkflowId',
    '/funnel/:initialTask/:funnelWorkflowId',
    '/login',
    '/verification-code',
    '/signup',
    '/signup-link/:token',
    '/forgot-password',
    '/reset/:uid/:token',
    '/disable-reminder',
    '/unsubscribe/:id',
    '/postformation',
    '/payroll/:companyUUID/contractors-onboarding/:contractorUUID',
    '/bookkeeping/invoicing/client-invoice/:invoiceId',
    '/xero-redirect',
];

/**
 * From the list of PUBLIC_ROUTES detects if the route
 * is there or not
 * TODO: This should be moved to the router instead of detecting manually
 */
export function isPublicRoute(route: string): boolean {
    const routeParts = route.split('/');
    const publicRouteParts = PUBLIC_ROUTES.map((publicRoute) => publicRoute.split('/'));

    return publicRouteParts.some((publicRoutePart) => {
        return publicRoutePart.every((publicRoutePartItem, index) => {
            return publicRoutePartItem === routeParts[index] || publicRoutePartItem.startsWith(':');
        });
    });
}

export const setLocalStorage = (key: string, value: any) => {
    if (value) {
        localStorage.setItem(key, JSON.stringify(value));
    } else {
        localStorage.removeItem(key);
    }
};

/**
 * Creates the initial state for the reducer taking
 * into account the legacy token
 */
export function buildInitialState() {
    const initialToken = localStorage.getItem('token');
    const initialUser = localStorage.getItem('user');

    let initialState: AuthState;

    try {
        initialState = {
            token: initialToken ? JSON.parse(initialToken) : null,
            user: initialUser ? JSON.parse(initialUser) : null,
            showTimeoutModal: false,
        };
    } catch (e) {
        initialState = {
            token: null,
            user: null,
            showTimeoutModal: false,
        };

        setLocalStorage('token', null);
        setLocalStorage('user', null);
        setLocalStorage('email', null);
        setLocalStorage('employee', false);
        setLocalStorage('impersonator', false);
        setLocalStorage('loggedin', false);
        setLocalStorage('groups', null);
    }

    return initialState;
}

export const setDebugUsers = (user: User) => {
    const id = user.pk.toString();

    let staff = localStorage.getItem(STAFF_ID);
    if (staff) {
        staff = JSON.parse(staff).toString();
    }
    const impersonating = Boolean(staff);

    const debugUser = {
        id,
        impersonating,
        staff,
    };

    // set Sentry vars
    Sentry.setUser({ ...debugUser });

    const ddUser = datadogRum.getUser();

    if (!ddUser) {
        datadogRum.setUser(debugUser);
    }

    // set Datadog vars
    Object.entries(debugUser).forEach(([key, value]) => {
        datadogRum.setUserProperty(key, value);
    });
};
