import {
    CustomContentProps,
    SnackbarProvider as NotistackSnackbarProvider,
    SnackbarContent,
    SnackbarKey,
    useSnackbar,
} from 'notistack';
import { Alert, AlertProps, IconButton } from '@mui/material';
import { ReactNode, forwardRef } from 'react';

import CheckCircleIcon from '../Icons/CheckCircleIcon';
import AlertExclamationCircleIcon from '../Icons/AlertExclamationCircleIcon';
import WarningExclamationIcon from '../Icons/WarningExclamationIcon';
import InformationCircleIcon from '../Icons/InformationCircleIcon';
import CloseXIcon from '../Icons/CloseXIcon';

/**
 * Default action button for the snackbar
 */
function DefaultActionButton(key: SnackbarKey) {
    const { closeSnackbar } = useSnackbar();

    return (
        <IconButton aria-label="close" color="inherit" size="small" onClick={() => closeSnackbar(key)}>
            <CloseXIcon fontSize="inherit" />
        </IconButton>
    );
}

interface CustomAlertProps extends CustomContentProps {
    disableAction?: boolean;
    alertProps?: AlertProps;
    customIcon?: ReactNode;
}

/**
 * Custom component for the snackbar using MUI Alert
 */
const CustomAlert = forwardRef<HTMLDivElement, CustomAlertProps>(
    ({ id, message, variant, action, iconVariant, hideIconVariant, disableAction, customIcon, alertProps }, ref) => {
        const AlertAction = typeof action === 'function' ? action(id) : action;
        const Icon = customIcon || iconVariant[variant];

        return (
            <SnackbarContent ref={ref} role="alert">
                <Alert
                    severity={variant}
                    icon={hideIconVariant ? false : Icon}
                    action={!disableAction ? AlertAction : null}
                    {...alertProps}>
                    {message}
                </Alert>
            </SnackbarContent>
        );
    }
);

export default function SnackbarProvider({ children, ...props }) {
    return (
        <NotistackSnackbarProvider
            autoHideDuration={3000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            Components={{
                success: CustomAlert,
                error: CustomAlert,
                warning: CustomAlert,
                info: CustomAlert,
            }}
            iconVariant={{
                success: <CheckCircleIcon />,
                error: <AlertExclamationCircleIcon />,
                warning: <WarningExclamationIcon />,
                info: <InformationCircleIcon />,
            }}
            action={DefaultActionButton}
            {...props}>
            {children}
        </NotistackSnackbarProvider>
    );
}
