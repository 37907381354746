import { ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import { SplitClient, SplitFactory } from '@splitsoftware/splitio-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ReactDOM from 'react-dom';
import { StatsigProvider } from 'statsig-react';

import theme from './theme';

import App from 'modules/App';
import SnackbarProvider from 'modules/common/SnackbarProvider/SnackbarProvider';
import config from 'modules/common/config';
import { setupDatadog, setupFetch, setupSentry, setupStripe, setupStatsig } from 'modules/common/integrations';
import { AuthContextProvider } from 'modules/Auth/AuthProvider';

import './assets/index.css';
/**
 * Integrations setup
 */
setupDatadog();
setupSentry();
setupStripe();
setupFetch();

const queryClient = new QueryClient();
const statsigConfig = setupStatsig();

ReactDOM.render(
    <Sentry.ErrorBoundary>
        <QueryClientProvider client={queryClient}>
            <StatsigProvider {...statsigConfig}>
                <SplitFactory config={config.split}>
                    <SplitClient splitKey={config.split.core.key}>
                        <StyledEngineProvider injectFirst>
                            <ThemeProvider theme={theme}>
                                <AuthContextProvider>
                                    <SnackbarProvider>
                                        <CssBaseline />
                                        <App />
                                    </SnackbarProvider>
                                </AuthContextProvider>
                            </ThemeProvider>
                        </StyledEngineProvider>
                    </SplitClient>
                </SplitFactory>
            </StatsigProvider>
        </QueryClientProvider>
    </Sentry.ErrorBoundary>,

    document.getElementById('root')
);
