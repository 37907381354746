import { createTheme } from '@mui/material/styles';

import palette from '../theme-v2/palette';

import colors from './colors';

const themeOptions = {
    palette: {
        ...palette,
        ...colors,
        grey: {
            ...colors.gray,
        },
        white: {
            main: '#ffffff',
            contrastText: '#000000',
        },
        black: {
            main: '#000000',
            contrastText: '#ffffff',
        },
        background: {
            default: '#ffffff',
        },
    },
    typography: {
        fontFamily: ['mier_bregular', 'mier_bbold'].join(','),
        h1: {
            fontFamily: 'recoletasemibold',
            fontSize: 40,
        },
        h2: {
            fontFamily: 'recoletasemibold',
            fontSize: 26,
        },
        h3: {
            fontFamily: 'recoletasemibold',
        },
        h4: {
            fontFamily: 'recoletasemibold',
            fontSize: 34,
            letterSpacing: '0.25px',
            lineHeight: '42px',
            fontWeight: 500,
        },
        h5: {
            fontFamily: 'recoletasemibold!important',
            fontWeight: '500!important',
            fontSize: '24px!important',
            lineHeight: '133.4%!important',
        },
        h6: {
            fontFamily: 'recoletasemibold',
        },
        buttonMedium: {
            fontFamily: 'mier_bregular!important',
            fontWeight: '600!important',
            fontSize: '14px!important',
            letterSpacing: '0.4px!important',
            lineHeight: '18px!important',
            textTransform: 'none',
        },
        buttonLarge: {
            fontFamily: 'mier_bregular',
            fontWeight: 600,
            fontSize: '17px',
            letterSpacing: '0.46px',
            lineHeight: '26px',
        },
        tooltip: {
            fontFamily: 'mier_bregular',
            fontWeight: 600,
            fontSize: '10px',
            lineHeight: '14px',
        },
        body1: {
            fontFamily: 'mier_bregular',
            fontSize: 17,
            fontWeight: 400,
            lineHeight: '25px',
            letterSpacing: '0.15px',
        },
        body2: {
            fontFamily: 'mier_bregular',
            fontWeight: 400,
            fontSize: '14px',
            letterSpacing: '0.25px',
            lineHeight: '20px',
        },
        subtitle1: {
            fontFamily: 'mier_bregular',
            fontWeight: 600,
            fontSize: 17,
            letterSpacing: '0.15px',
            lineHeight: '30px',
        },
        inputLabel: {
            fontFamily: 'mier_bregular',
            fontWeight: 400,
            fontSize: '12px',
            letterSpacing: '0.15px',
            lineHeight: '14px',
        },
        tableHeader: {
            fontFamily: 'mier_bbold',
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '24px',
            letterSpacing: '0.17px',
        },
        caption2: {
            fontFamily: 'mier_bregular',
            fontSize: 14,
            fontWeight: 400,
            lineHeight: '23.24px',
            letterSpacing: '0.4px',
        },
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: '#fff',
                    boxShadow: '0px 1px 4px -1px rgba(0, 0, 0, 0.07)',
                },
            },
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    border: 0,
                    boxShadow: '0px 1px 4px -1px rgba(0, 0, 0, 0.07)',
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    height: '36px',
                    fontFamily: 'mier_bregular',
                    fontWeight: 400,
                    fontSize: '17px',
                    letterSpacing: '0.15px',
                    lineHeight: '25px',
                },
            },
        },
        MuiModal: {
            defaultProps: {
                disablePortal: true, // messes with tailwind because portal happens outside of dom, which is outside of #root's domain
            },
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    height: '36px',
                },
            },
        },
        MuiButton: {
            defaultProps: {
                variant: 'contained',
            },
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    fontFamily: 'mier_bbold',
                    fontSize: 14,
                },
                sizeLarge: {
                    padding: '10px 32px',
                },
                outlined: {
                    borderWidth: '2px',
                    '&:hover': { borderWidth: '2px' },
                },
            },
        },
        MuiLoadingButton: {
            defaultProps: {
                variant: 'contained',
            },
        },
    },
};

/**
 * @deprecated do not use this theme, use theme-v2 instead
 */
const theme = createTheme(themeOptions);

/**
 * @deprecated do not use this theme, use theme-v2 instead
 */
export const themeRed = createTheme({
    ...themeOptions,
    palette: { ...themeOptions.palette, primary: { ...colors.red } },
});

/**
 * @deprecated do not use this theme, use theme-v2 instead
 */
export default theme;
