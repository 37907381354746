import { useEffect } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import * as Sentry from '@sentry/react';
import { ErrorBoundary } from 'react-error-boundary';

import useUser from 'modules/HubV2/common/queries/user';
import lazyWithSuspenseAndRetry from 'modules/common/lazyWithSuspenseAndRetry';
import browserHistory from 'modules/common/browserHistory';
import AnalyticsTracking from 'modules/common/AnalyticsTracking';
import useAuthHandlers from 'modules/Auth/AuthProvider/useAuthHandlers';
import useRefreshTimer from 'modules/Auth/AuthProvider/useRefreshTimer';
import ClarityTracking from 'modules/common/ClarityTracking';
import config from 'modules/common/config';
import useAuthSelectors from 'modules/Auth/AuthProvider/useAuthSelector';

const Error = lazyWithSuspenseAndRetry(() => import('./Error'));
const Root = lazyWithSuspenseAndRetry(() => import('./Root'));
const TaxSavingsCalculatorRoutes = lazyWithSuspenseAndRetry(() => import('modules/TaxSavingsCalculator'));
const FunnelRoutes = lazyWithSuspenseAndRetry(() => import('modules/Funnel'));
const SSCv05Routes = lazyWithSuspenseAndRetry(() => import('modules/Funnel/SSC'));
const ManualSalesFlowRoutes = lazyWithSuspenseAndRetry(() => import('modules/Funnel/ManualSalesFlow'));
const AuthRoutes = lazyWithSuspenseAndRetry(() => import('modules/Auth'));
const CheckoutRoutes = lazyWithSuspenseAndRetry(() => import('modules/Checkout'));
const WelcomeRoutes = lazyWithSuspenseAndRetry(() => import('modules/Welcome'));
const OnboardingRoutes = lazyWithSuspenseAndRetry(() => import('modules/Onboarding'));
const DashboardRoutes = lazyWithSuspenseAndRetry(() => import('modules/Dashboard'));
const HubRoutes = lazyWithSuspenseAndRetry(() => import('modules/Hub'));
const HubV2Routes = lazyWithSuspenseAndRetry(() => import('modules/HubV2'));
const TaxRoutes = lazyWithSuspenseAndRetry(() => import('modules/Tax'));
const NotFound = lazyWithSuspenseAndRetry(() => import('./NotFound'));
const Deeplink = lazyWithSuspenseAndRetry(() => import('./Deeplink'));

const stripePromise = loadStripe(config.stripe.apiKey);

function App() {
    const { checkToken } = useAuthHandlers();
    const { startTimer, isTimerStarted } = useRefreshTimer();
    const { user } = useAuthSelectors('user');

    useEffect(() => {
        checkToken();
    }, [checkToken]);

    useEffect(() => {
        if (!isTimerStarted) {
            startTimer();
        }
    }, [isTimerStarted, startTimer]);

    const handleError = (error, info) => {
        Sentry.captureException(error, {
            info,
            user: user?.email,
        });
    };

    return (
        <div data-testid="app" className="collective-wrapper">
            <Elements stripe={stripePromise}>
                <Router history={browserHistory}>
                    <ErrorBoundary onError={handleError} FallbackComponent={Error}>
                        {/* Segment tracking */}
                        <AnalyticsTracking />
                        {/* Clarity tracking */}
                        <ClarityTracking />

                        <Switch>
                            <Route exact path="/" component={Root} />
                            {/* future route for the homepage */}
                            {/* <Route exact path="/home" component={Home} /> */}
                            <Route path="/tax-savings-calculator" component={TaxSavingsCalculatorRoutes} />
                            <Route path="/manual-sales" component={ManualSalesFlowRoutes} />
                            <Route path="/ssc-v05" component={SSCv05Routes} />
                            <Route path="/funnel" component={FunnelRoutes} />
                            <Route
                                path={[
                                    '/login',
                                    '/verification-code',
                                    '/signup',
                                    '/signup-link/:token',
                                    '/forgot-password',
                                    '/reset/:uid/:token',
                                    '/disable-reminder',
                                    '/unsubscribe/:id',
                                    '/postformation',
                                ]}
                                component={AuthRoutes}
                            />
                            <Route
                                path={['/checkout', '/order-*', '/scorp-*', '/llc-*', '/sp-*']}
                                component={CheckoutRoutes}
                            />
                            <Route path="/onboarding-welcome-*" component={WelcomeRoutes} />
                            <Route
                                path={['/onboarding', '/llc-mercury-account-finish', '/qre/overview', '/offboard']}
                                component={OnboardingRoutes}
                            />
                            <Route
                                path={[
                                    '/dashboard',
                                    '/bookkeeping',
                                    '/payroll',
                                    '/profile',
                                    '/support',
                                    '/reimbursements',
                                    '/documents',
                                ]}
                                component={DashboardRoutes}
                            />
                            <Route path={['/hub/*', '/client-info/*', '/transition-plan/*']} component={HubRoutes} />
                            <Route path="/hub-v2" component={HubV2Routes} />
                            <Route path={['/tax', '/docusign-success']} component={TaxRoutes} />
                            <Route path="/deeplink/:hash" component={Deeplink} />
                            <Route path="/error" component={Error} />
                            <Route component={NotFound} />
                        </Switch>
                    </ErrorBoundary>
                </Router>
            </Elements>
        </div>
    );
}

export default App;
