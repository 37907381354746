import { datadogRum } from '@datadog/browser-rum';

import config from 'modules/common/config';

export function getFirstAccessDeviceType() {
    const firstAccessDeviceType = sessionStorage.getItem('first_access_device_type');

    if (!firstAccessDeviceType) {
        const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        const deviceType = isMobile ? 'Mobile' : 'Desktop';

        sessionStorage.setItem('first_access_device_type', deviceType);

        return deviceType;
    }

    return firstAccessDeviceType;
}

export default function setupDatadog() {
    if (config.environment && ['production', 'stage'].includes(config.environment) && !!datadogRum) {
        datadogRum.init({
            applicationId: config.datadog.rumAppId ?? '',
            clientToken: config.datadog.rumClientToken ?? '',
            site: 'datadoghq.com',
            service: 'collective-frontend',
            env: config.environment,
            sampleRate: 100,
            trackInteractions: true,
            trackResources: true,
        });

        // Set activity info into user sessions
        datadogRum.setGlobalContextProperty('activity', {
            firstAccessDeviceType: getFirstAccessDeviceType(),
        });
    }
}
