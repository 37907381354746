import SplitIO from '@splitsoftware/splitio/types/splitio';

const featureFlags = [
    'BK_9_edit_payroll',
    'BK_16_offcycle_payroll',
    'BK_494_Payroll_TOS_Popup',
    'NHP_4700_Transition_Plan_Revised_Policy',
    'NHP_4824_Consent_Modals_In_Dashboard',
    'TAX_75_New_TaxTracker',
    'TAX_151_show_messagingModal',
    'TAX_465_BTR_docusign',
    'CA_542_ITR_Pricing',
    'ON_CSV_Tool',
    'ON_1075_Gusto_Onboarding_Flow',
    'ON_1659_Add_Schedule_C_Onboarding_Logic',
    'TAX_501_ALLOW_CSAT_SURVEY',
    'CX_52_ALLOW_NOTIFICATIONS',
    'ONB_ACCOUNTANT_TRACKER',
    'BK_1029_hurdlr_dashboard',
    'BK_Hurdlr_Switch_To_UUID',
    'TAX_1353_BTR_ORGANIZER_2023',
    'BK_1331_hurdlr_custom_bank_account_page',
    'BK_1441_PERSONAL_ACCOUNT_UNLINK',
    'BK_1275_Change_Plaid_Products',
    'TAX_ORGANIZER_DEBUGGER_PANEL',
    'BK_NEW_MONTHLY_BOOKS_QUEUE_STATS_BAR',
    'BK_1513_NEW_BK_REPORTS',
    'BK_Hurdlr_Transaction_Ingestion',
    'ON_FORMATION_REVAMP',
    'ON_FORMATION_HUB_REVAMP',
    'cx_bulk_download_enabled',
] as const;

type FeatureFlagsData = {
    // eslint-disable-next-line prettier/prettier
    [K in (typeof featureFlags)[number]]: string;
};

const splitLocalhostConfig =
    import.meta.env.VITE_SPLIT_API_KEY === 'localhost'
        ? {
              features: featureFlags.reduce((result, flag) => ({ ...result, [flag]: 'on' }), {}),
              scheduler: {
                  offlineRefreshRate: 15,
              },
          }
        : {};

interface Config {
    split: SplitIO.IBrowserSettings;
    featureFlags: FeatureFlagsData;
    debug: boolean;
    taxCalcSubmissionEventId?: string;
    frontendURL: string;
    companyName?: string;
    companyURL?: string;
    djangoURL?: string;
    environment?: string;
    datadog: {
        rumAppId?: string;
        rumClientToken?: string;
    };
    googleAnalytics: {
        enabled: boolean;
    };
    sentry: {
        enabled: boolean;
        dsn?: string;
    };
    sourceVersion?: string;
    otp: {
        timeResendLimitSeconds: number;
        allowedVerifyAttempts: number;
        allowedResendAttempts: number;
        validitySeconds: number;
    };
    notifications: {
        dropdownSize: number;
    };
    companyEmail?: string;
    helloSign: {
        clientId?: string;
    };
    front: {
        formHookURL?: string;
        formHelpMessageId?: string;
    };
    facebook: {
        token?: string;
    };
    stripe: {
        apiKey?: string;
    };
    retool: {
        url?: string;
    };
    statsig: {
        sdkKey?: string;
    };
    hurdlr: {
        clientId?: string;
        url: {
            web?: string;
            api?: string;
        };
    };
    sfmc: {
        trainingCall: {
            journeyKey: string;
            exitJourneyKeyList: string[];
        };
    };
    maintenance: {
        scheduleDate?: string;
    };
}

const config: Config = {
    split: {
        core: {
            authorizationKey: import.meta.env.VITE_SPLIT_API_KEY ?? '',
            key: 'anonymous',
            trafficType: 'account',
        },
        ...splitLocalhostConfig,
    },
    featureFlags: featureFlags.reduce((result, flag) => ({ ...result, [flag]: flag }), {} as FeatureFlagsData),
    debug: import.meta.env.VITE_DEBUG === 'true',
    taxCalcSubmissionEventId: import.meta.env.VITE_TAX_CALC_SUBMISSION_EVENT_ID,
    frontendURL: import.meta.env.VITE_FE_URL || `${import.meta.env.HEROKU_APP_NAME}.herokuapp.com`,
    companyName: import.meta.env.VITE_COMPANY_NAME,
    companyURL: import.meta.env.VITE_COMPANY_URL,
    djangoURL: import.meta.env.VITE_DJANGO_URL,
    environment: import.meta.env.VITE_ENVIRONMENT,
    datadog: {
        rumAppId: import.meta.env.VITE_DATADOG_RUM_APP_ID,
        rumClientToken: import.meta.env.VITE_DATADOG_RUM_CLIENT_TOKEN,
    },
    googleAnalytics: {
        enabled: import.meta.env.VITE_GOOGLE_ANALYTICS_ENABLED === 'true',
    },
    sentry: {
        enabled: import.meta.env.VITE_SENTRY_ENABLED === 'true',
        dsn: import.meta.env.VITE_SENTRY_DSN,
    },
    sourceVersion: import.meta.env.SOURCE_VERSION,
    otp: {
        allowedResendAttempts: Number(import.meta.env.VITE_OTP_ALLOWED_RESEND_ATTEMPTS) || 3,
        allowedVerifyAttempts: Number(import.meta.env.VITE_OTP_ALLOWED_VERIFY_ATTEMPTS) || 5,
        timeResendLimitSeconds: Number(import.meta.env.VITE_OTP_TIME_RESEND_LIMIT_SECONDS) || 0,
        validitySeconds: Number(import.meta.env.VITE_OTP_VALIDITY_SECONDS) || 1800, // 30 minutes
    },
    notifications: {
        dropdownSize: Number(import.meta.env.VITE_NOTIFICATIONS_DROPDOWN_COUNT) || 5,
    },
    companyEmail: import.meta.env.VITE_COMPANY_EMAIL,
    helloSign: {
        clientId: import.meta.env.VITE_HS_CLIENT_ID,
    },
    front: {
        formHookURL: import.meta.env.VITE_FRONT_FORM_HOOK_URL,
        formHelpMessageId: import.meta.env.VITE_FRONT_FORM_HELP_MESSAGE_ID,
    },
    facebook: {
        token: import.meta.env.VITE_FACEBOOK_TOKEN,
    },
    stripe: {
        apiKey: import.meta.env.VITE_STRIPE,
    },
    retool: {
        url: import.meta.env.VITE_RETOOL_URL,
    },
    statsig: {
        sdkKey: import.meta.env.VITE_STATSIG_SDK_KEY,
    },
    hurdlr: {
        clientId: import.meta.env.VITE_HURDLR_CLIENT_ID,
        url: {
            api: import.meta.env.VITE_HURDLR_API_URL,
            web: import.meta.env.VITE_HURDLR_WEB_URL,
        },
    },
    sfmc: {
        trainingCall: {
            journeyKey: import.meta.env.VITE_TRAINING_CALL_JOURNEY_KEY ?? '',
            exitJourneyKeyList: (import.meta.env.VITE_TRAINING_CALL_EXIT_JOURNEY_KEYS ?? '').split(','),
        },
    },
    maintenance: {
        scheduleDate: import.meta.env.VITE_MAINTENANCE_SCHEDULE_DATE || '',
    },
};

export default config;
