import { localViews } from 'modules/Auth/AuthProvider/views';
import config from 'modules/common/config';

/**
 * FIXME remove this as soon as we have all the remaining fetch requests migrated to collectiveApi
 */
export default function setupFetch() {
    const originalFetch = window.fetch;
    window.originalFetch = window.fetch;
    window.fetch = function (...args) {
        return new Promise((resolve, reject) => {
            const staffTokenWhileImpersonate = localViews.staffDetails().token?.access;

            console.log(staffTokenWhileImpersonate, args);

            if (staffTokenWhileImpersonate && args[0].startsWith(config.djangoURL)) {
                if (args.length === 2) {
                    args[1] = {
                        ...args[1],
                        headers: {
                            ...args[1]?.headers,
                            'X-Staff-Token-While-Impersonate': `JWT ${staffTokenWhileImpersonate}`,
                        },
                    };
                } else {
                    args.push({
                        headers: {
                            'X-Staff-Token-While-Impersonate': `JWT ${staffTokenWhileImpersonate}`,
                        },
                    });
                }
            }

            originalFetch
                .apply(this, args)
                .then((response) => {
                    if (response.status === 401) {
                        window.console.log('Session expired. Redirecting to login page.');
                        window.localStorage.removeItem('loggedin');
                        window.localStorage.removeItem('token');
                        window.localStorage.removeItem('staffTokenWhileImpersonate');
                        window.localStorage.removeItem('refresh');
                        window.localStorage.removeItem('user');
                        window.location.href = config.frontendURL;
                    }
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}
