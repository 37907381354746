import { localViews } from 'modules/Auth/AuthProvider/views';

type Headers = {
    [name: string]: string | number;
};

export default function getCollectiveHeaders() {
    const localToken = localViews.token();
    const token = localToken?.access;
    const ephemeralToken = localToken?.ephemeral_token;
    const headers: Headers = {};

    if (token) {
        headers.Authorization = `JWT ${token}`;
    } else if (ephemeralToken) {
        headers.Authorization = `Token ${ephemeralToken}`;
    }

    const staffTokenWhileImpersonate = localViews.staffDetails()?.token;
    if (staffTokenWhileImpersonate?.access) {
        headers['X-Staff-Token-While-Impersonate'] = `JWT ${staffTokenWhileImpersonate.access}`;
    }

    return headers;
}
