/**
 * This file contains the colors as described in our System Design
 * @link https://www.figma.com/file/QRtl04PVeGCMA15TPzABp9/MUI-Design-System-2023
 */

export const black = '#000000';

export const white = '#FFFFFF';

export const purple = {
    50: '#F5F1F6',
    100: '#E7DCE7',
    200: '#C2A7C3',
    300: '#9D74A0',
    400: '#7E4781',
    500: '#5C1761',
    600: '#4E1453',
    700: '#401044',
    800: '#370E3B',
    900: '#2D0B30',
};

export const red = {
    50: '#FFF8F9',
    100: '#FEE6E7',
    200: '#FDB7BA',
    300: '#FB8B90',
    400: '#FB7379',
    500: '#FA5A60',
    600: '#ED4F52',
    700: '#DF4343',
    800: '#AD383A',
    900: '#7B2C2F',
};

export const orange = {
    50: '#FFFAF7',
    100: '#FFF0E5',
    200: '#FFD4B7',
    300: '#FFB888',
    400: '#FFA972',
    500: '#FF995B',
    600: '#F68C56',
    700: '#EC7E50',
    800: '#B76640',
    900: '#7D4B2D',
};

export const green = {
    50: '#F2F9F7',
    100: '#D9EDE8',
    200: '#92CCBD',
    300: '#4DAC94',
    400: '#289B7E',
    500: '#008866',
    600: '#017B5C',
    700: '#006D52',
    800: '#015943',
    900: '#004332',
};

export const grey = {
    50: '#FAF9F6',
    100: '#F6F3EB',
    200: '#EFECE4',
    300: '#E5E0D2',
    400: '#CECABD',
    500: '#A3998F',
    600: '#857D75',
    700: '#625C57',
    800: '#383531',
    900: '#000000DE',
};

export const blue = {
    500: '#0094FF',
};
