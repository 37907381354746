/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback } from 'react';
import * as Sentry from '@sentry/react';
import jwt_decode from 'jwt-decode';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';
import { datadogRum } from '@datadog/browser-rum';

import { IMPERSONATE_LAST_PAGE, STAFF_EMAIL, STAFF_ID, STAFF_TOKEN_WHILE_IMPERSONATE } from './constants';

import collectiveApi from 'modules/common/collectiveApi';
import browserHistory from 'modules/common/browserHistory';
import { setAnalyticsUserId } from 'modules/common/AnalyticsTracking';
import config from 'modules/common/config';
import Token from 'modules/Auth/common/Token.interface';
import User from 'modules/Auth/common/User.interface';
import DecodedToken from 'modules/Auth/common/DecodedToken.interface';
import { Actions, AuthDispatch, AuthHandlers } from 'modules/Auth/AuthProvider/Auth.interface';
import { useAuthContext } from 'modules/Auth/AuthProvider';
import { localViews } from 'modules/Auth/AuthProvider/views';
import { isPublicRoute, setLocalStorage } from 'modules/Auth/AuthProvider/utils';

dayjs.extend(utc);
dayjs.extend(duration);

const log = (message: string) => {
    if (config.debug) {
        // eslint-disable-next-line no-console
        console.log(message);
    }
};

const getDecodedToken = (token: string) => {
    return jwt_decode<DecodedToken>(token);
};

const isTokenExpired = (token: DecodedToken): boolean => {
    const now = dayjs().unix();
    return token.exp < now;
};

let refreshToken: (actions: Actions, dispatch: AuthDispatch, callback?: (...args) => Promise<any>) => Promise<boolean>;

/**
 * Fetch the logged user info and stores it in the AuthContext
 */
async function fetchAuthenticationData(actions: Actions, dispatch: AuthDispatch) {
    const { data: user } = await collectiveApi.get<User>('rest-auth/user/');
    dispatch(actions.setUserData(user));
    setAnalyticsUserId(user.pk);
}

/**
 * Method to impersonate a member if the logged staff user
 * has the impersonate permission
 */
async function impersonateStart(actions: Actions, dispatch: AuthDispatch, member_id: number | string) {
    const staffToken = localViews.token();
    const staffEmail = localViews.user()?.email;
    const staffId = localViews.user()?.pk;

    if (!staffToken?.access) {
        log('No staff token found');
        return false;
    }

    setLocalStorage(STAFF_EMAIL, staffEmail);
    setLocalStorage(STAFF_ID, staffId);
    let data;

    try {
        const response = await collectiveApi.post<{ token: string; refresh: string; email: string }>('impersonate/', {
            member_id,
        });
        data = response.data;
    } catch (error: any) {
        Sentry.captureException(error);
        datadogRum.addError(error, {
            type: 'IMPERSONATE_START',
            member_id,
            staffId,
        });
        return false;
    }

    datadogRum.stopSession();

    dispatch(actions.setToken({ access: data.token, refresh: data.refresh } as Token));
    dispatch(actions.setUserData({ email: data.email } as User));

    setLocalStorage(STAFF_TOKEN_WHILE_IMPERSONATE, staffToken);
    setLocalStorage('lastActive', null);
    const { pathname } = window.location;
    setLocalStorage(IMPERSONATE_LAST_PAGE, pathname);

    datadogRum.setUserProperty('impersonating', true);
    datadogRum.setUserProperty('staff', staffId);

    setTimeout(() => {
        browserHistory.push('/');
        window.location.replace('/');
    }, 1000);
    return true;
}

/**
 * Stops the impersonation of a member
 * and restores the staff user session
 */
async function impersonateEnd(actions: Actions, dispatch: AuthDispatch) {
    const staffDetails = localViews.staffDetails();

    const staffEmail = staffDetails.email;
    const staffId = staffDetails.id;
    const staffToken = staffDetails.token as Token;

    const decodedToken = getDecodedToken(staffToken.access);

    if (!isTokenExpired(decodedToken)) {
        try {
            await collectiveApi.delete('impersonate/');
        } catch (error: any) {
            Sentry.captureException(error);
            datadogRum.addError(error, {
                type: 'IMPERSONATE_END',
                staffId,
            });
        }
    } else {
        log('staff token expired');
    }

    if (!staffToken?.access) {
        log('No staff token found');
        return false;
    }

    datadogRum.stopSession();
    datadogRum.clearUser();

    setLocalStorage(STAFF_EMAIL, null);
    setLocalStorage(STAFF_ID, null);
    setLocalStorage(STAFF_TOKEN_WHILE_IMPERSONATE, null);

    dispatch(actions.setToken(staffToken));
    dispatch(actions.setUserData({ email: staffEmail, pk: staffId } as unknown as User));

    await refreshToken(actions, dispatch);
    await fetchAuthenticationData(actions, dispatch);

    const redirectPath = localStorage.getItem(IMPERSONATE_LAST_PAGE) || '/hub-v2';
    setLocalStorage(IMPERSONATE_LAST_PAGE, null);
    browserHistory.push(JSON.parse(redirectPath));
    window.location.replace(JSON.parse(redirectPath));

    return true;
}

/**
 * Logs out the user, clears the session data from the AuthProvider
 * and also from the localStorage.
 * If the user is impersonating, it will also stop the impersonation
 */
async function logout(actions: Actions, dispatch: AuthDispatch, callback?: (...args) => Promise<any>) {
    const isImpersonating = localViews.isImpersonating();
    if (isImpersonating) {
        await impersonateEnd(actions, dispatch);
    }
    const refresh = localViews.token()?.refresh;
    if (refresh) {
        try {
            await collectiveApi.post('rest-auth/logout/', {
                refresh,
            });
        } catch (error: any) {
            Sentry.captureException(error);
        }
        datadogRum.stopSession();
    }

    dispatch(actions.clearSession());
    datadogRum.clearUser();

    if (callback) {
        await callback();
    }
}

/**
 * Checks local storage for an existing, correct token
 * @param refreshRightAway indicates that the existing token needs to be refreshed
 */
async function checkToken(actions: Actions, dispatch: AuthDispatch, refreshRightAway = false) {
    const token = localViews.token();

    // TODO: This shouldn't be in charge of detecting if is public or not
    // we should update the router to be more smart on detecting
    // what's public or not
    if (!token?.access && isPublicRoute(browserHistory.location.pathname)) {
        return null;
    }

    if (!token?.access) {
        await logout(actions, dispatch, async () => {
            browserHistory.push('/login');
        });
        return null;
    }

    const accessTokenDecoded = getDecodedToken(token.access);
    const diff = dayjs.unix(accessTokenDecoded.exp).diff(dayjs());
    const remainingMillisecs = dayjs.duration(diff).asMilliseconds();

    if (refreshRightAway) {
        const isTokenRefreshed = await refreshToken(actions, dispatch);
        return isTokenRefreshed;
    }

    if (remainingMillisecs > 0) {
        log(`Gonna refresh token in ${dayjs.utc(diff / 2).format('HH:mm:ss')}`);
        (window as any).refreshTimeOut = setTimeout(() => {
            refreshToken(actions, dispatch);
        }, remainingMillisecs / 2);
        return null;
    }

    const isTokenRefreshed = await refreshToken(actions, dispatch);
    return isTokenRefreshed;
}

/**
 * Method in charge of refreshing the auth token and
 * checking if the user is still logged in
 */
refreshToken = async (actions: Actions, dispatch: AuthDispatch, callback?: (...args) => Promise<any>) => {
    const refresh = localViews.token()?.refresh;

    if (!refresh) {
        log('No refresh token found');
        return false;
    }

    if (isTokenExpired(getDecodedToken(refresh))) {
        await logout(actions, dispatch);
        return false;
    }

    try {
        const { data } = await collectiveApi.post<Token>('rest-auth/refresh-token/', { refresh });
        if (data?.access) {
            dispatch(actions.setToken(data));
            if (callback) {
                await callback(data);
            }
            await checkToken(actions, dispatch);
        }
    } catch (err: any) {
        // if the refresh token is expired, we logout the user
        if (err.response?.status !== 401) {
            Sentry.captureException(err);
        }

        if (err.response?.status === 401) {
            await logout(actions, dispatch);
            return false;
        }
    }

    return true;
};

/**
 * After a successfull login, there are some methods that are ran to get more data
 * setup the time interval process to refresh the token, but we also add
 * a callback method to be executed after checking the token for any other methods
 * that need to be executed after login
 */
async function postLoginDataFetch(
    actions: Actions,
    dispatch: AuthDispatch,
    callback?: (...args) => Promise<any>,
    redirect = true
) {
    let redirectPath = '/';
    await fetchAuthenticationData(actions, dispatch);

    // clear the timeout which refreshes the auth token
    if ((window as any).refreshTimeOut) {
        clearTimeout((window as any).refreshTimeOut);
    }

    await checkToken(actions, dispatch);
    setLocalStorage('loggedin', true);

    if (callback) {
        redirectPath = await callback(redirect, localViews.isEmployee());
    }

    return redirectPath;
}

/**
 * Logs the user in. Stores the user data in the AuthProvider
 * and also in the localStorage.
 */
async function login(
    actions: Actions,
    dispatch: AuthDispatch,
    email: string,
    password: string,
    fingerprint: string,
    onLoginSuccess?: (...args) => Promise<any>,
    onLoginFailed?: (...args) => Promise<any>
) {
    if (localViews.token()?.access) {
        await logout(actions, dispatch);
    }

    const loginErrorResponse = {
        success: false,
        errors: {
            message: 'Incorrect email or password. Please check your credentials and try again.',
        },
    };
    const username = email.trim();

    let redirectPath = '';
    let redirectPathProps = {};
    let successfulLogin = false;

    try {
        const { data } = await collectiveApi.post<Token>(
            'rest-auth/login/',
            {
                username,
                password,
                fingerprint,
            },
            {
                withCredentials: true,
            }
        );

        if (data) {
            const {
                access,
                refresh,
                ephemeral_token: ephemeralToken,
                funnel_workflow_id: funnelWorkflowId,
                funnel_workflow_path: funnelWorkflowPath,
            } = data;
            if (access && refresh) {
                successfulLogin = true;
                dispatch(actions.setUserData({ email }));
                dispatch(actions.setToken(data));
                if (funnelWorkflowId) {
                    localStorage.setItem('funnel_workflow_id', funnelWorkflowId);
                    redirectPath = `/${funnelWorkflowPath}/resume/${funnelWorkflowId}`;
                } else {
                    redirectPath = await postLoginDataFetch(actions, dispatch, onLoginSuccess);
                }
            } else if (ephemeralToken) {
                successfulLogin = true;
                redirectPath = '/verification-code';
                redirectPathProps = {
                    ephemeralToken,
                    fingerprint,
                    email,
                };
            }
        }

        if (!successfulLogin) {
            if (onLoginFailed) {
                await onLoginFailed({ actions, dispatch });
            }
            await logout(actions, dispatch);
            return loginErrorResponse;
        }

        return {
            success: true,
            data: {
                redirectPath,
                redirectPathProps,
            },
        };
    } catch (error: any) {
        if (onLoginFailed) {
            await onLoginFailed({ actions, dispatch });
        }
        logout(actions, dispatch);
        if (error.response?.status === 500 || !error.response?.status) {
            Sentry.captureException(error);
        }
        if (error?.response) {
            const { data } = error.response;
            loginErrorResponse.errors = { ...data };
        }

        return loginErrorResponse;
    }
}

/**
 * Verifies the otp code with the ephemeral token in the local storage after
 * a successful login. If the code is correct, it will store the user data
 * and the token in the AuthProvider and also in the localStorage.
 * Also if a callback is passed, it will execute it verification process while
 * retrieving the user info.
 */
async function verifyVerificationCode(actions: Actions, dispatch: AuthDispatch, callback, otp, fingerprint = null) {
    try {
        const response = await collectiveApi.post(`rest-auth/login/code/`, {
            ephemeral_token: localViews.token()?.ephemeral_token,
            username: localViews.user()?.email.trim(),
            code: otp,
            fingerprint,
        });

        // axios interceptor for this endpoint returns null if the code is invalid
        // so we need to handle it here
        if (!response || response?.data?.hasErrors) {
            return {
                error: 'Code Invalid.',
                invalidCode: true,
            };
        }

        const { data } = response;
        dispatch(actions.setToken(data));
        const redirectPath = await postLoginDataFetch(actions, dispatch, callback);
        return {
            redirectPath,
        };
    } catch (error: unknown | any) {
        const defaultErrorMessage =
            'Unable to verify one-time password. Please check your internet connection or try again later.';

        if (error.response?.status === 500) {
            Sentry.captureException(error);
        }
        return {
            error: error.response?.data?.error || defaultErrorMessage,
        };
    }
}

async function sendVerificationCode() {
    try {
        const data = await collectiveApi.post(`rest-auth/login/resend-code/`, {});
        return data;
    } catch (error: unknown | any) {
        const defaultErrorMessage = 'Unable to resend otp. Try again later.';
        if (error.response?.status === 500) {
            Sentry.captureException(error);
        }
        return {
            error: error.response?.data?.error || defaultErrorMessage,
        };
    }
}

/**
 * Wraps the authentication methods and impersonator feature to use
 * the AuthContext actions and dispatch methods to update it's state
 * @example const { login, logout } = useAuthHandlers();
 */
export default function useAuthHandlers(): AuthHandlers {
    const context = useAuthContext();

    if (!context) {
        throw new Error('useAuthHandlers must be used within an AuthContextProvider');
    }
    const { actions, dispatch } = context;

    return {
        login: (email, password, fingerprint, onLoginSuccess, onLoginFailed) =>
            login(actions, dispatch, email, password, fingerprint, onLoginSuccess, onLoginFailed),
        logout: (callback) => logout(actions, dispatch, callback),
        impersonateStart: (member_id) => impersonateStart(actions, dispatch, member_id),
        impersonateEnd: () => impersonateEnd(actions, dispatch),
        verifyVerificationCode: (otp, fingerprint, callback) =>
            verifyVerificationCode(actions, dispatch, callback, otp, fingerprint),
        sendVerificationCode: () => sendVerificationCode(),
        checkToken: useCallback(
            (refreshRightAway = false) => checkToken(actions, dispatch, refreshRightAway),
            [actions, dispatch]
        ),
        fetchAuthenticationData: () => fetchAuthenticationData(actions, dispatch),
        actions,
        dispatch,
    };
}
