import { Actions } from './Auth.interface';

/**
 * These actions are the one that prepares the payload to be recieved
 * by the reducer's dispatch method
 */
const actions: Actions = {
    setToken: (token) => ({ type: 'SET_TOKEN', payload: token }),
    clearSession: () => ({ type: 'CLEAR_SESSION', payload: null }),
    setUserData: (data) => ({ type: 'SET_USER_DATA', payload: data }),
    setExtendSessionModalVisible: (visible) => ({ type: 'SET_EXTEND_SESSION_MODAL_VISIBLE', payload: visible }),
};

export default actions;
