import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import usePageVisibility from '../hooks/usePageVisibility';
import { getCookie } from '../utils';

import { collectiveTracking } from './utils';

function AnalyticsTracking() {
    const location = useLocation();
    const isVisible = usePageVisibility();

    useEffect(
        () =>
            collectiveTracking.track({
                name: 'App Loaded',
                properties: {
                    category: 'app_lifecycle',
                    action: 'app_loaded',
                },
            }),
        []
    );

    useEffect(() => {
        let pageviewProperties = {};

        /**
         * This is setting all the experiments and it's variations in the pageview event.
         * We do this so we can filter the metrics and make sure the user is in the correct
         * variation and getting the correct experience.
         * This cookie is set in the collective-landing project from SSR pages. So only flows
         * that came from there will have this cookie.
         * If a user navigates directly to a collective-app page (with a fresh session) without
         * passing through collective-landing, you won't have this cookie.
         *
         * E.g. This is used to get the SSC experiment variation from statsig
         *      We only count the metrics if the user is in the /ssc-v05/ URL and also in the variation_1, or
         *      if the user is in the /funnel/ URL and also in the control variation.
         */
        const experimentsVariations = getCookie('experiments_variations');

        if (experimentsVariations) {
            pageviewProperties = {
                experiment_variation: decodeURIComponent(experimentsVariations),
            };
        }

        collectiveTracking.pageview(pageviewProperties);
    }, [location.pathname]);

    useEffect(
        () =>
            collectiveTracking.track({
                name: isVisible ? 'App Visible' : 'App Hidden',
                properties: {
                    category: 'app_lifecycle',
                    action: isVisible ? 'app_visible' : 'app_hidden',
                },
            }),
        [isVisible]
    );

    return null;
}

export default AnalyticsTracking;
