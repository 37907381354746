import { loadStripe } from '@stripe/stripe-js';

import config from 'modules/common/config';

/**
 * Stripe initialization
 * needs to be loaded for all pages for the app to be PCI compliant
 */
export default function setupStripe() {
    loadStripe(config.stripe.apiKey);
}
