import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { localViews } from 'modules/Auth/AuthProvider/views';

function ClarityTracking() {
    const location = useLocation();
    const [retryCount, setRetryCount] = useState(0);

    useEffect(() => {
        const userId = localViews.user()?.pk?.toString() || null;
        if (window.clarity) {
            // set user id as Clarity customID
            window.clarity('identify', userId);
        } else {
            // Retry mechanism when Clarity is not yet available
            if (retryCount < 2) {
                const retryTimeout = setTimeout(() => {
                    setRetryCount(retryCount + 1);
                }, 500);
                return () => clearTimeout(retryTimeout);
            }
            return console.error('Clarity script not loaded');
        }
    }, [location, retryCount]);
    return null;
}

export default ClarityTracking;
