import * as Sentry from '@sentry/react';
import { v4 as uuidv4 } from 'uuid';

import { setCookie, getCookie } from 'modules/common/utils';

export const setAnalyticsUserId = (userId: number | null) => {
    const expireDate = new Date();
    const encodedUserId = btoa(userId ? userId.toString() : '');
    if (userId) {
        // 8h match the token expiration period
        expireDate.setTime(expireDate.getTime() + 8 * 60 * 60 * 1000);
        window.localStorage.setItem('cltv_user_id', encodedUserId);
    } else {
        expireDate.setTime(expireDate.getTime() - 1000);
        window.localStorage.removeItem('cltv_user_id');
    }
    setCookie('cltv_user_id', encodedUserId, expireDate, import.meta.env.VITE_TRACKING_SCRIPT_COOKIE_DOMAIN);
};

export const getAnalyticsUserId = () => {
    const userId = window.localStorage.getItem('cltv_user_id') || getCookie('cltv_user_id') || null;
    return userId ? decodeURIComponent(userId) : null;
};

export const collectiveTracking = {
    track: <T = object>({ name, properties }: { name: string; properties: T }) => {
        const props = { location: window.location.href, ...properties };
        collectiveTracking.sendEvent({ eventType: 'track', name, properties: props });
    },
    pageview: (properties = {}) => {
        collectiveTracking.sendEvent({ eventType: 'page', name: '', properties });
    },
    getAnonymousId: () => {
        let cltv_anonymous_id = window.collectiveTracking?.('getAnonymousId') as string | undefined;

        if (!cltv_anonymous_id || Number(cltv_anonymous_id)) {
            cltv_anonymous_id = getCookie('cltv_anonymous_id') || uuidv4();

            localStorage.setItem('cltv_anonymous_id', cltv_anonymous_id);
        }

        return cltv_anonymous_id;
    },
    sendEvent: <T = object>({
        eventType,
        name = '',
        properties,
    }: {
        eventType: string;
        name?: string;
        properties?: T;
    }) => {
        try {
            const userId = getAnalyticsUserId();
            window.collectiveTracking?.('sendEvent', eventType, name, properties, userId);
        } catch (e) {
            Sentry.captureException(e, { contexts: { sendEvent: { eventType, name, properties } } });
        }
    },
};
