/**
 * @deprecated do not use this theme, use theme-v2 instead
 */
export default {
    /** @deprecated */
    green: {
        50: '#F2F9F7',
        100: '#D9EDE8',
        200: '#92CCBD',
        300: '#4DAC94',
        400: '#289B7E',
        500: '#008866',
        600: '#017B5C',
        700: '#006D52',
        800: '#015943',
        900: '#004332',
        A100: '#D9EDE8',
        A200: '#92CCBD',
        A400: '#289B7E',
        A700: '#006D52',
        main: '#008866',
        light: '#4DAC94',
        dark: '#006D52',
        contrastText: '#FFFFFF',
    },
    /** @deprecated */
    orange: {
        50: '#FFFAF7',
        100: '#FFF0E5',
        200: '#FFD4B7',
        300: '#FFB888',
        400: '#FFA972',
        500: '#FF995B',
        600: '#F68C56',
        700: '#EC7E50',
        800: '#B76640',
        900: '#7D4B2D',
        A100: '#FFF0E5',
        A200: '#FFD4B7',
        A400: '#FFA972',
        A700: '#EC7E50',
        main: '#FF995B',
        light: '#FFB888',
        dark: '#EC7E50',
        contrastText: '#FFFFFF',
    },
    /** @deprecated */
    red: {
        50: '#FFF8F9',
        100: '#FEE6E7',
        200: '#FDB7BA',
        300: '#FB8B90',
        400: '#FB7379',
        500: '#FA5A60',
        600: '#ED4F52',
        700: '#DF4343',
        800: '#AD383A',
        900: '#7B2C2F',
        A100: '#FEE6E7',
        A200: '#FDB7BA',
        A400: '#FB7379',
        A700: '#DF4343',
        main: '#FA5A60',
        light: '#FB8B90',
        dark: '#DF4343',
        contrastText: '#FFFFFF',
    },
    /** @deprecated */
    warmGray: {
        50: '#FAF9F6',
        100: '#F6F3EB',
        200: '#EFECE4',
        300: '#E5E0D2',
        400: '#CECABD',
        500: '#A3998F',
        600: '#857D75',
        700: '#625C57',
        800: '#383531',
        900: '#000000DE',
        A100: '#F6F3EB',
        A200: '#EFECE4',
        A400: '#CECABD',
        A700: '#625C57',
        main: '#A3998F',
        light: '#E5E0D2',
        dark: '#625C57',
        contrastText: '#FFFFFF',
    },
    /** @deprecated */
    yellow: {
        50: '#FFFEF7',
        100: '#FFFDF0',
        200: '#FFF9D9',
        300: '#FFF5C2',
        400: '#FFEE94',
        500: '#FFE666',
        600: '#E6CF5C',
        700: '#BFAD4D',
        800: '#998A3D',
        900: '#7D7132',
        A100: '#FFFDF0',
        A200: '#FFF9D9',
        A400: '#FFEE94',
        A700: '#BFAD4D',
        main: '#FFF5C2',
        light: '#FFEE94',
        dark: '#BFAD4D',
        contrastText: '#FFFFFF',
    },
    /** @deprecated */
    purple: {
        50: '#F5F1F6',
        100: '#E7DCE7',
        200: '#C2A7C3',
        300: '#9D74A0',
        400: '#7E4781',
        500: '#5C1761',
        600: '#4E1453',
        700: '#401044',
        800: '#370E3B',
        900: '#2D0B30',
        A100: '#E7DCE7',
        A200: '#C2A7C3',
        A400: '#7E4781',
        A700: '#401044',
        main: '#5C1761',
        light: '#9D74A0',
        dark: '#401044',
        contrastText: '#FFFFFF',
    },
    /** @deprecated */
    gray: {
        50: '#FAF9F6',
        100: '#F6F3EB',
        200: '#EFECE4',
        300: '#E5E0D2',
        400: '#CECABD',
        500: '#A3998F',
        600: '#857D75',
        700: '#625C57',
        800: '#383531',
        900: '#000000DE',
        A100: '#F6F3EB',
        A200: '#EFECE4',
        A400: '#CECABD',
        A700: '#625C57',
        main: '#A3998F',
        light: '#E5E0D2',
        dark: '#625C57',
        contrastText: '#FFFFFF',
    },
};
