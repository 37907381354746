import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import config from 'modules/common/config';

export default function setupSentry() {
    if (config.sentry.enabled) {
        Sentry.init({
            dsn: config.sentry.dsn,
            ignoreErrors: [
                'ResizeObserver loop limit exceeded',
                'ResizeObserver loop completed with undelivered notifications.',
                'Failed to fetch',
                'TypeError: Failed to fetch',
            ],
            integrations: [
                new Integrations.BrowserTracing({
                    tracingOrigins: [
                        'collective-frontend-dev.herokuapp.com',
                        'collective-frontend-stage.herokuapp.com',
                        'collective-frontend-prod.herokuapp.com',
                        'app.collective.com',
                    ],
                }),
            ],
            environment: config.environment,
            release: config.sourceVersion,
        });
    }
}
