import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const WarningExclamationIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon viewBox="0 0 25 25" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.3253 3.89301C11.48 1.89301 14.3668 1.89301 15.5215 3.89301L22.4497 15.893C23.6044 17.893 22.161 20.393 19.8516 20.393H5.9952C3.6858 20.393 2.24242 17.893 3.39712 15.893L10.3253 3.89301ZM13.7894 4.89301C13.4045 4.22634 12.4423 4.22634 12.0574 4.89301L5.12917 16.893C4.74427 17.5597 5.2254 18.393 5.9952 18.393H19.8516C20.6214 18.393 21.1025 17.5597 20.7176 16.893L13.7894 4.89301ZM12.9234 8.393C13.4757 8.393 13.9234 8.84072 13.9234 9.393V11.393C13.9234 11.9453 13.4757 12.393 12.9234 12.393C12.3711 12.393 11.9234 11.9453 11.9234 11.393V9.393C11.9234 8.84072 12.3711 8.393 12.9234 8.393ZM11.9234 15.393C11.9234 14.8407 12.3711 14.393 12.9234 14.393H12.9334C13.4857 14.393 13.9334 14.8407 13.9334 15.393C13.9334 15.9453 13.4857 16.393 12.9334 16.393H12.9234C12.3711 16.393 11.9234 15.9453 11.9234 15.393Z"
            />
        </SvgIcon>
    );
};

export default WarningExclamationIcon;
