import { useState } from 'react';
import dayjs from 'dayjs';
import jwt_decode from 'jwt-decode';

import { Actions, AuthDispatch, AuthHandlers } from './Auth.interface';
import useAuthHandlers from './useAuthHandlers';
import useAuthSelectors from './useAuthSelector';

import { localViews } from 'modules/Auth/AuthProvider/views';
import DecodedToken from 'modules/Auth/common/DecodedToken.interface';

let TIMER: NodeJS.Timer;

function getRemainingTimeInSecs(token: DecodedToken) {
    const now = dayjs();
    const expiresAt = dayjs(token.exp * 1000);
    const diff = expiresAt.diff(now, 'seconds');
    return diff;
}

function getRemainingTimeRefreshToken() {
    if (localViews.token()?.refresh) {
        const refreshToken = jwt_decode<DecodedToken>(localViews.token().refresh);
        return getRemainingTimeInSecs(refreshToken);
    }
    return 0;
}

function startTimer(actions: Actions, dispatch: AuthDispatch, setIsTimerStarted) {
    if (TIMER) {
        clearInterval(TIMER);
    }
    const interval = getRemainingTimeRefreshToken() * 900; // In milliseconds (90%)

    if (interval) {
        TIMER = setInterval(() => {
            dispatch(actions.setExtendSessionModalVisible(true));
        }, interval);
        setIsTimerStarted(true);
    }
}

function refreshTimer(
    checkToken: AuthHandlers['checkToken'],
    actions: Actions,
    dispatch: AuthDispatch,
    isTimeoutModalVisible: boolean,
    setIsTimerStarted
) {
    dispatch(actions.setExtendSessionModalVisible(false));
    startTimer(actions, dispatch, setIsTimerStarted);
    if (!isTimeoutModalVisible) {
        checkToken(true);
    }
}

export default function useRefreshTimer() {
    const { checkToken, actions, dispatch } = useAuthHandlers();
    const { isTimeoutModalVisible } = useAuthSelectors('isTimeoutModalVisible');
    const [isTimerStarted, setIsTimerStarted] = useState<boolean>(false);

    return {
        refreshTimer: () => refreshTimer(checkToken, actions, dispatch, isTimeoutModalVisible, setIsTimerStarted),
        startTimer: () => startTimer(actions, dispatch, setIsTimerStarted),
        isTimerStarted,
    };
}
