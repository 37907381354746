import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const CheckCircleIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon viewBox="0 0 25 25" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.9233 4.39301C8.50506 4.39301 4.92334 7.97473 4.92334 12.393C4.92334 16.8113 8.50506 20.393 12.9233 20.393C17.3416 20.393 20.9233 16.8113 20.9233 12.393C20.9233 7.97473 17.3416 4.39301 12.9233 4.39301ZM2.92334 12.393C2.92334 6.87016 7.40049 2.39301 12.9233 2.39301C18.4462 2.39301 22.9233 6.87016 22.9233 12.393C22.9233 17.9159 18.4462 22.393 12.9233 22.393C7.40049 22.393 2.92334 17.9159 2.92334 12.393ZM16.6304 9.6859C17.021 10.0764 17.021 10.7096 16.6304 11.1001L12.6304 15.1001C12.2399 15.4906 11.6068 15.4906 11.2162 15.1001L9.21623 13.1001C8.82571 12.7096 8.82571 12.0764 9.21623 11.6859C9.60676 11.2954 10.2399 11.2954 10.6304 11.6859L11.9233 12.9788L15.2162 9.6859C15.6068 9.29537 16.2399 9.29537 16.6304 9.6859Z"
            />
        </SvgIcon>
    );
};

export default CheckCircleIcon;
