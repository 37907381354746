import { PaletteOptions } from '@mui/material';

import { purple, red, orange, green, grey, white, black } from './colors';

const palette: PaletteOptions = {
    // MUI palette options
    mode: 'light',
    primary: {
        main: purple[500],
        dark: purple[700],
        light: purple[300],
        contrastText: white,
    },
    secondary: {
        main: grey[500],
        dark: grey[700],
        light: grey[300],
        contrastText: black,
    },
    error: {
        main: red[500],
        dark: red[700],
        light: red[300],
        contrastText: white,
    },
    warning: {
        main: orange[500],
        dark: orange[700],
        light: orange[300],
        contrastText: white,
    },
    info: {
        main: purple[500],
        dark: grey[50],
        light: white,
        contrastText: black,
    },
    success: {
        main: green[500],
        dark: green[700],
        light: green[300],
        contrastText: black,
    },
    text: {
        primary: grey[900],
        secondary: grey[500],
        disabled: grey[300],
    },
    action: {
        active: black,
        hover: grey[50],
        selected: grey[100],
        disabled: grey[300],
        disabledBackground: grey[50],
        focus: grey[400],
    },
    background: {
        default: grey[50],
        paper: white,
    },
    divider: grey[200],
    common: {
        black,
        white,
    },
    // Custom palette options
    purple: {
        ...purple,
        main: purple[500],
        dark: purple[700],
        light: purple[300],
        contrastText: white,
    },
    red: {
        ...red,
        main: red[500],
        dark: red[700],
        light: red[300],
        contrastText: white,
    },
    orange: {
        ...orange,
        main: orange[500],
        dark: orange[700],
        light: orange[300],
        contrastText: white,
    },
    green: {
        ...green,
        main: green[500],
        dark: green[700],
        light: green[300],
        contrastText: white,
    },
    grey,
    shades: {
        primary: {
            '4p': `${purple[500]}0A`,
            '8p': `${purple[500]}14`,
            '12p': `${purple[500]}1F`,
            '30p': `${purple[500]}4D`,
            '50p': `${purple[500]}80`,
        },
        secondary: {
            '4p': grey[50],
            '8p': grey[50],
            '12p': grey[100],
            '30p': grey[100],
            '50p': grey[200],
        },
        error: {
            '4p': `${red[500]}0A`,
            '12p': `${red[500]}4D`,
            '30p': `${red[500]}80`,
            '50p': `${purple[500]}80`,
            '160p': black,
            '190p': red[100],
        },
        warning: {
            '4p': `${orange[500]}0A`,
            '12p': `${orange[500]}1F`,
            '30p': `${orange[500]}4D`,
            '50p': `${orange[500]}80`,
            '160p': black,
            '190p': orange[100],
        },
        info: {
            '4p': `${purple[500]}0A`,
            '12p': `${purple[500]}1F`,
            '30p': `${purple[500]}4D`,
            '50p': `${purple[500]}80`,
            '160p': black,
            '190p': purple[100],
        },
        success: {
            '4p': `${green[500]}0A`,
            '12p': `${green[500]}1F`,
            '30p': `${green[500]}4D`,
            '50p': `${green[500]}80`,
            '160p': black,
            '190p': green[100],
        },
    },
};

export default palette;
