import { useAuthContext } from 'modules/Auth/AuthProvider';
import { Views } from 'modules/Auth/AuthProvider/Auth.interface';
import { views as authViews } from 'modules/Auth/AuthProvider/views';

type Selectors = keyof Views;

type SelectorResult<K extends Selectors> = K extends Selectors ? ReturnType<Views[K]> : never;

type Results<T extends Selectors[]> = {
    [K in T[number]]: SelectorResult<K>;
};

/**
 * Hook to get the data from the AuthContext already parsed from the different views
 * @example const { isAuthenticated, isEmployee } = useAuthSelectors('isAuthenticated', 'isEmployee');
 */
const useAuthSelectors = <T extends Selectors[]>(...selectors: T): Results<T> => {
    const context = useAuthContext();

    if (!context) {
        throw new Error('useAuthSelectors must be used within an AuthContextProvider');
    }
    const { state } = context;

    return selectors.reduce(
        (result, selector) => ({
            ...result,
            [selector]: authViews[selector](state),
        }),
        {} as Results<T>
    );
};

export default useAuthSelectors;
