import { sha256 } from 'js-sha256';

export const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
        return parts.pop().split(';').shift();
    }
};

export const setCookie = (name, value, expireDate, domain) => {
    const domainStr = domain ? `; domain=${domain}` : '';
    const expireStr = expireDate ? `; expires=${expireDate.toUTCString()}` : '';
    document.cookie = `${name}=${encodeURIComponent(value || '')}${expireStr}${domainStr}; samesite=Lax; path=/`;
};

export const getInitials = (fullname) => {
    return fullname
        .split(' ')
        .map((item) => item.toUpperCase().slice(0, 1))
        .join('');
};

export const setEnhancedConversionData = (email) => {
    if (email && email.length > 0) {
        const normalizedEmail = email.trim().toLowerCase();
        const hashedEmail = sha256(normalizedEmail);
        localStorage.setItem('enhanced_conversion_data_email', hashedEmail);
    }
};
