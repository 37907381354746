import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const InformationCircleIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon viewBox="0 0 25 25" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.5898 4.66101C8.17157 4.66101 4.58984 8.24273 4.58984 12.661C4.58984 17.0793 8.17157 20.661 12.5898 20.661C17.0081 20.661 20.5898 17.0793 20.5898 12.661C20.5898 8.24273 17.0081 4.66101 12.5898 4.66101ZM2.58984 12.661C2.58984 7.13816 7.067 2.66101 12.5898 2.66101C18.1127 2.66101 22.5898 7.13816 22.5898 12.661C22.5898 18.1839 18.1127 22.661 12.5898 22.661C7.067 22.661 2.58984 18.1839 2.58984 12.661ZM11.5898 8.66101C11.5898 8.10873 12.0376 7.66101 12.5898 7.66101H12.5998C13.1521 7.66101 13.5998 8.10873 13.5998 8.66101C13.5998 9.2133 13.1521 9.66101 12.5998 9.66101H12.5898C12.0376 9.66101 11.5898 9.2133 11.5898 8.66101ZM10.5898 12.661C10.5898 12.1087 11.0376 11.661 11.5898 11.661H12.5898C13.1421 11.661 13.5898 12.1087 13.5898 12.661V15.661C14.1421 15.661 14.5898 16.1087 14.5898 16.661C14.5898 17.2133 14.1421 17.661 13.5898 17.661H12.5898C12.0376 17.661 11.5898 17.2133 11.5898 16.661V13.661C11.0376 13.661 10.5898 13.2133 10.5898 12.661Z"
            />
        </SvgIcon>
    );
};

export default InformationCircleIcon;
