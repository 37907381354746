import axios from 'axios';
import { datadogRum } from '@datadog/browser-rum';

import getCollectiveHeaders from './getCollectiveHeaders';

import config from 'modules/common/config';

const collectiveApi = axios.create({
    baseURL: config.djangoURL,
    headers: { 'Content-Type': 'application/json' },
});

collectiveApi.interceptors.request.use((cfg) => {
    const headers = getCollectiveHeaders();
    cfg.headers = {
        ...cfg.headers,
        ...headers,
    };
    return cfg;
});

collectiveApi.interceptors.response.use(
    (response) => response,
    (error) => {
        datadogRum.addError(error, {
            url: error?.config?.url,
            method: error?.config?.method,
        });

        const shouldIgnore401Error = [
            'rest-auth/login/resend-code/',
            'rest-auth/login/code/',
            'rest-auth/login/',
        ].includes(error?.config?.url);
        // Checks if the error out was from axios.
        if (axios.isAxiosError(error) && error.response?.status === 401) {
            // Logout on auth failure.
            if (!shouldIgnore401Error) {
                window.console.log('Session expired. Redirecting to login page.');
                window.localStorage.removeItem('loggedin');
                window.localStorage.removeItem('token');
                window.localStorage.removeItem('refresh');
                window.location.href = config.frontendURL ?? '/';
            }
            // Stop this error so that it won’t bubble up to the top scope and trigger Sentry
            // Added data in return to fix deconstruction usage in the app
            return { data: { ...(error?.response?.data ?? {}), hasErrors: true } };
        }
        return Promise.reject(error);
    }
);

export default collectiveApi;
