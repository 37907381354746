import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const CloseXIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon viewBox="0 0 25 25" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.21623 5.79289C6.60676 5.40237 7.23992 5.40237 7.63045 5.79289L12.9233 11.0858L18.2162 5.79289C18.6068 5.40237 19.2399 5.40237 19.6304 5.79289C20.021 6.18342 20.021 6.81658 19.6304 7.20711L14.3376 12.5L19.6304 17.7929C20.021 18.1834 20.021 18.8166 19.6304 19.2071C19.2399 19.5976 18.6068 19.5976 18.2162 19.2071L12.9233 13.9142L7.63045 19.2071C7.23992 19.5976 6.60676 19.5976 6.21623 19.2071C5.82571 18.8166 5.82571 18.1834 6.21623 17.7929L11.5091 12.5L6.21623 7.20711C5.82571 6.81658 5.82571 6.18342 6.21623 5.79289Z"
            />
        </SvgIcon>
    );
};

export default CloseXIcon;
