import { v4 as uuidv4 } from 'uuid';

import config from 'modules/common/config';
import { getCookie, setCookie } from 'modules/common/utils';
import { collectiveTracking } from 'modules/common/AnalyticsTracking/utils';

/**
 * Setup Statsig SDK
 */
export default function setupStatsig() {
    let statsig_user_id = getCookie('statsig_user_id');

    if (!statsig_user_id) {
        const expireDate = new Date();

        statsig_user_id = uuidv4();

        // Set cookie to expire in 1 year
        expireDate.setTime(expireDate.getTime() + 365 * 24 * 60 * 60 * 1000);
        setCookie(
            'statsig_user_id',
            statsig_user_id,
            expireDate,
            import.meta.env.VITE_TRACKING_SCRIPT_COOKIE_DOMAIN // FIXME - change to something like VITE_COOKIE_DOMAIN for general use
        );
    }

    return {
        sdkKey: config.statsig.sdkKey || '',
        user: {
            userID: statsig_user_id,
            customIDs: {
                segmentAnonymousID: collectiveTracking.getAnonymousId(),
            },
        },
        options: {
            environment: {
                tier: import.meta.env.VITE_ENVIRONMENT === 'local' ? 'development' : import.meta.env.VITE_ENVIRONMENT,
            },
        },
    };
}
