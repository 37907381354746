import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const AlertExclamationCircleIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon viewBox="0 0 25 25" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.5898 4.66101C8.17157 4.66101 4.58984 8.24273 4.58984 12.661C4.58984 17.0793 8.17157 20.661 12.5898 20.661C17.0081 20.661 20.5898 17.0793 20.5898 12.661C20.5898 8.24273 17.0081 4.66101 12.5898 4.66101ZM2.58984 12.661C2.58984 7.13816 7.067 2.66101 12.5898 2.66101C18.1127 2.66101 22.5898 7.13816 22.5898 12.661C22.5898 18.1839 18.1127 22.661 12.5898 22.661C7.067 22.661 2.58984 18.1839 2.58984 12.661ZM12.5898 7.66101C13.1421 7.66101 13.5898 8.10873 13.5898 8.66101V12.661C13.5898 13.2133 13.1421 13.661 12.5898 13.661C12.0376 13.661 11.5898 13.2133 11.5898 12.661V8.66101C11.5898 8.10873 12.0376 7.66101 12.5898 7.66101ZM11.5898 16.661C11.5898 16.1087 12.0376 15.661 12.5898 15.661H12.5998C13.1521 15.661 13.5998 16.1087 13.5998 16.661C13.5998 17.2133 13.1521 17.661 12.5998 17.661H12.5898C12.0376 17.661 11.5898 17.2133 11.5898 16.661Z"
            />
        </SvgIcon>
    );
};

export default AlertExclamationCircleIcon;
