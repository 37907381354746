import { Views, LocalViews } from './Auth.interface';
import { STAFF_EMAIL, STAFF_ID, STAFF_TOKEN_WHILE_IMPERSONATE } from './constants';

/**
 * Gets a parsed json structure from the local storage
 */
const getFromStorage = (key: string, defaultValue = {}) => {
    try {
        return JSON.parse(localStorage.getItem(key) || `${defaultValue}`);
    } catch (error) {
        return defaultValue;
    }
};

/**
 * This set of views will return the data already parsed from the AuthProvider
 * state, thus making it easier to use in the components
 */
export const views: Views = {
    isEmployee: (state) => Boolean(state.user?.is_employee),
    isImpersonator: (state) => Boolean(state.user?.groups?.includes('Impersonators')),
    isImpersonating: () => Boolean(localStorage.getItem(STAFF_TOKEN_WHILE_IMPERSONATE)),
    isTokenFromEmployee: (state) => Boolean(state.token?.groups?.includes('Employee')),
    isAuthenticated: (state) => Boolean(state.token),
    user: (state) => state.user,
    token: (state) => state.token,
    staffDetails: () => ({
        token: getFromStorage(STAFF_TOKEN_WHILE_IMPERSONATE),
        email: getFromStorage(STAFF_EMAIL, '""'),
        id: getFromStorage(STAFF_ID, '""'),
    }),
    isPageRefreshing: (state) => Boolean(state.token) && !state.user,
    isTimeoutModalVisible: (state) => state.showTimeoutModal,
    impersonateLastPage: () => getFromStorage('impersonateLastPage'),
};

/**
 * This set of views is to be used in methods that can't access the AuthProvider
 * but still need to check the user's session.
 * It's a mirror of the views above, but it uses localStorage instead of the
 * AuthProvider state
 */
export const localViews: LocalViews = {
    isEmployee: () => Boolean(localStorage.getItem('employee')),
    isImpersonator: () => Boolean(localStorage.getItem('impersonator')),
    isImpersonating: () => Boolean(localStorage.getItem(STAFF_TOKEN_WHILE_IMPERSONATE)),
    isTokenFromEmployee: () => Boolean(getFromStorage('token')?.groups?.includes('Employee')),
    isAuthenticated: () => Boolean(localStorage.getItem('token')),
    user: () => getFromStorage('user'),
    token: () => getFromStorage('token'),
    staffDetails: () => ({
        token: getFromStorage(STAFF_TOKEN_WHILE_IMPERSONATE),
        email: getFromStorage(STAFF_EMAIL, '""'),
        id: getFromStorage(STAFF_ID, '""'),
    }),
    isPageRefreshing: () => Boolean(localStorage.getItem('token')) && !localStorage.getItem('user'),
    isTimeoutModalVisible: () => false,
    impersonateLastPage: () => getFromStorage('impersonateLastPage'),
};
