import { useState, useEffect, useRef } from 'react';

import { VisibilityChangeEventProps, VisibilityStateProps } from './usePageVisibility.interfaces';

function getBrowserVisibilityProp(): VisibilityChangeEventProps {
    if (typeof document !== 'undefined') {
        if (typeof document.hidden !== 'undefined') {
            return 'visibilitychange';
        }
        if (typeof (document as any).msHidden !== 'undefined') {
            return 'msvisibilitychange';
        }
        if (typeof (document as any).webkitHidden !== 'undefined') {
            return 'webkitvisibilitychange';
        }
    }
    return null;
}

function getBrowserDocumentHiddenProp(): VisibilityStateProps {
    if (typeof document !== 'undefined') {
        if (typeof document.hidden !== 'undefined') {
            return 'hidden';
        }
        if (typeof (document as any).msHidden !== 'undefined') {
            return 'msHidden';
        }
        if (typeof (document as any).webkitHidden !== 'undefined') {
            return 'webkitHidden';
        }
    }
    return null;
}

function getIsDocumentHidden(): boolean {
    const prop = getBrowserDocumentHiddenProp();
    return prop ? (document as any)[prop] : false;
}

export default function usePageVisibility(): boolean {
    const [isVisible, setIsVisible] = useState<boolean>(!getIsDocumentHidden());
    const lastReportedVisibility = useRef<boolean>(isVisible);

    const onVisibilityChange = () => {
        const currentVisibility = !getIsDocumentHidden();
        if (currentVisibility !== lastReportedVisibility.current) {
            setIsVisible(currentVisibility);
            lastReportedVisibility.current = currentVisibility;
        }
    };

    useEffect(() => {
        const visibilityChange = getBrowserVisibilityProp();

        if (visibilityChange) {
            document.addEventListener(visibilityChange, onVisibilityChange, false);

            return () => {
                document.removeEventListener(visibilityChange, onVisibilityChange);
            };
        }
    }, []);

    return isVisible;
}
