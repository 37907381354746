import { createContext, useContext, useReducer, useMemo } from 'react';

import { reducer, initialState } from 'modules/Auth/AuthProvider/reducer';
import { AuthContextType, AuthContextProviderProps } from 'modules/Auth/AuthProvider/Auth.interface';
import actions from 'modules/Auth/AuthProvider/actions';

const AuthContext = createContext<AuthContextType>({} as AuthContextType);
AuthContext.displayName = 'AuthContext';

export const useAuthContext = () => useContext(AuthContext);

export function AuthContextProvider({ children, ...props }: AuthContextProviderProps) {
    const [state, dispatch] = useReducer(reducer, { ...initialState, ...props });
    const value = useMemo<AuthContextType>(() => ({ state, actions, dispatch }), [state, dispatch]);

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
